exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-sections-about-js": () => import("./../../../src/pages/sections/about.js" /* webpackChunkName: "component---src-pages-sections-about-js" */),
  "component---src-pages-sections-base-js": () => import("./../../../src/pages/sections/base.js" /* webpackChunkName: "component---src-pages-sections-base-js" */),
  "component---src-pages-sections-contact-js": () => import("./../../../src/pages/sections/contact.js" /* webpackChunkName: "component---src-pages-sections-contact-js" */),
  "component---src-pages-sections-intro-js": () => import("./../../../src/pages/sections/intro.js" /* webpackChunkName: "component---src-pages-sections-intro-js" */),
  "component---src-pages-sections-work-js": () => import("./../../../src/pages/sections/work.js" /* webpackChunkName: "component---src-pages-sections-work-js" */)
}

